import React from 'react';
import { Footer } from 'flowbite-react';

function FooterComponent({ labels }) {
  return (
    <Footer container={true}>
      <div className="w-full text-center">
        <Footer.Copyright href="#" by={labels.name} year={2024} /> {/* Dynamic name */}
        <Footer.LinkGroup>
          <Footer.Link href="https://github.com/Sonofendor" target="_blank" rel="noopener noreferrer">Github</Footer.Link>
          <Footer.Link href="https://www.linkedin.com/in/artem-grishechko/" target="_blank" rel="noopener noreferrer">LinkedIn</Footer.Link>
        </Footer.LinkGroup>
      </div>
    </Footer>
  );
}

export default FooterComponent;
