import React from 'react';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import profilePic from '../assets/me.jpg';

function HeroSection({ labels }) {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleButtonClick = () => {
    navigate(`/${labels.lang}/about`); // Navigate to About page based on the current language
  };

  return (
    <section className="relative flex items-center justify-between bg-gradient-to-r from-white to-transparent py-12 px-6 lg:px-24">
      {/* Left Side: Text */}
      <div className="max-w-lg z-10">
        <h2 className="text-5xl font-bold text-gray-800">{labels.title}</h2>
        <p className="text-lg text-gray-600 mt-4">{labels.description}</p>
        <Button className="mt-6" gradientDuoTone="purpleToBlue" onClick={handleButtonClick}>
          {labels.ctaButton}
        </Button>
      </div>

      {/* Right Side: Photo */}
      <div className="w-1/2">
        <img
          src={profilePic}
          alt="Your Photo"
          className="w-full rounded-l-full object-cover opacity-90"
        />
      </div>
    </section>
  );
}

export default HeroSection;
