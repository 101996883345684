import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import Header from './components/Header';
import FooterComponent from './components/Footer';
import NotFound from './components/NotFound';
import About from './components/About';  // Import About component
import Portfolio from './components/Portfolio';  // Import Portfolio component
import enLabels from './locales/en.json';
import ruLabels from './locales/ru.json';

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect from root to /en */}
        <Route path="/" element={<Navigate to="/en" replace />} />

        {/* English Routes */}
        <Route path="/en" element={<PageWithLanguage lang="en" />}>
          <Route index element={<HeroSection labels={enLabels} />} />
          <Route path="about" element={<About lang="en" />} /> {/* Pass lang to About */}
          <Route path="portfolio" element={<Portfolio lang="en" />} /> {/* Pass lang to Portfolio */}
          <Route path="*" element={<NotFound />} /> {/* 404 for /en routes */}
        </Route>

        {/* Russian Routes */}
        <Route path="/ru" element={<PageWithLanguage lang="ru" />}>
          <Route index element={<HeroSection labels={ruLabels} />} />
          <Route path="about" element={<About lang="ru" />} /> {/* Pass lang to About */}
          <Route path="portfolio" element={<Portfolio lang="ru" />} /> {/* Pass lang to Portfolio */}
          <Route path="*" element={<NotFound />} /> {/* 404 for /ru routes */}
        </Route>

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} /> {/* Global 404 */}
      </Routes>
    </Router>
  );
}

function PageWithLanguage({ lang }) {
  const [labels, setLabels] = useState(enLabels);  // Default to English

  useEffect(() => {
    if (lang === 'ru') {
      setLabels({ ...ruLabels, lang });  // Set Russian labels
    } else {
      setLabels({ ...enLabels, lang });  // Set English labels
    }
  }, [lang]);  // Rerun when lang changes

  return (
    <div>
      <Header labels={labels} lang={lang} />
      <main>
        <Routes>
          <Route index element={<HeroSection labels={labels} />} />  {/* Home */}
          <Route path="about" element={<About lang={lang} />} />  {/* About */}
          <Route path="portfolio" element={<Portfolio lang={lang} />} />  {/* Portfolio */}
          <Route path="*" element={<NotFound />} />  {/* 404 */}
        </Routes>
      </main>
      <FooterComponent labels={labels} />
    </div>
  );
}

export default App;
