import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function About({ lang }) {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const filePath = lang === 'ru' ? '/about_ru.md' : '/about_en.md'; // Fetch the correct file
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownContent(text);
      });
  }, [lang]);

  return (
    <div className="max-w-3xl mx-auto p-4 my-8 prose">
      <ReactMarkdown>{markdownContent}</ReactMarkdown> {/* Added prose class for better styling */}
    </div>
  );
}

export default About;
