import React from 'react';
import { Link } from 'react-router-dom';

function Header({ labels, lang }) {
  return (
    <header className="bg-gray-800 text-white py-4 px-8 flex justify-between items-center">
      <div className="text-lg font-bold">
        <Link to={`/${lang}/`} className="hover:text-gray-400">
          {labels.name} {/* Dynamic name from the locale */}
        </Link>
      </div>
      <nav className="space-x-4">
        <Link to={`/${lang}/`} className="hover:text-gray-400">{labels.home}</Link> {/* Dynamic Home */}
        <Link to={`/${lang}/about`} className="hover:text-gray-400">{labels.about}</Link> {/* Dynamic About */}
        <Link to={`/${lang}/portfolio`} className="hover:text-gray-400">{labels.portfolio}</Link> {/* Dynamic Portfolio */}
      </nav>
      <div className="space-x-2">
        <Link to="/en/" className={`hover:text-gray-400 ${lang === 'en' ? 'underline' : ''}`}>
          English
        </Link>
        <Link to="/ru/" className={`hover:text-gray-400 ${lang === 'ru' ? 'underline' : ''}`}>
          Русский
        </Link>
      </div>
    </header>
  );
}

export default Header;
